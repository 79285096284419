import axios from "axios";
const qs = require("qs");

export class SearchService {

  static async getFiltersApi() {
    return await axios.get("search/filters").then(d => d.data);
  }

  static async getPodcasts(options = {}) {
    return await axios.get(`podcasts?${qs.stringify(options)}`);
  }
}
