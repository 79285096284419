import { useRef } from "react";
import TopicsItem from "../../components/TopicsItem/TopicsItem";
import { Pagination } from "../../../../../../components";
import styles from "./TopicsAllPage.module.scss";
import { useTopicsPage } from "@podcasts/hooks";

export function TopicsAllPage({ title = "" }) {
  const myRef: any = useRef(null);    
  const { 
    data: { data, last_page, current_page },
    order,
    setOrder,
    isSavedTopic,
    onPageChange,
    onSaveTopic
  } = useTopicsPage({ per_page: 12, defaultOrder: "asc", order_by: "title" });

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <div
        ref={myRef}
        className={`${styles["title-box"]} d-flex justify-content-between align-items-center`}
      >
        <h2>{title}</h2>
        <div className={`${styles["sort"]} d-flex align-items-center`} onClick={() => setOrder(order === "asc" ? "desc" : "asc")} >
          <span>Sort&nbsp;</span>
          <div className={`${order === "desc" ? styles["up"] : styles["down"]}`}>
            <i className="icon-chevron-sown"></i>
          </div>
        </div>
      </div>
      <div className={`${styles["topics-items"]}`}>
        {data.map((item: any, i: number) => (
          <div key={i} className={`${styles["topic-wrapp"]}`}>
            <TopicsItem
              {...item}
              saved={isSavedTopic(item.id)}
              onToggleFavorite={(e) => {
                e.stopPropagation();
                onSaveTopic(item);
              }}
            />
          </div>
        ))}
      </div>
      <Pagination
        pages={last_page}
        page={current_page}
        onPageChange={(page: number) => {
          onPageChange(page)
          executeScroll();
        }}
      />
    </>
  );
}

export default TopicsAllPage;
