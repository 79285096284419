import { useSelector } from "react-redux";
import { get } from 'lodash';
export * from './library-base'
export * from './api'
export * from './useFeaturedPage';
export * from './useTopicsPage';
export * from './useSpeakersPage';
export * from './useCoursesPage';
export * from './useSavedSpeakersPage';
export * from './useSavedCoursesPage';
export * from './useSavedTopicsPage';
export * from './useRecentlyViewedPage';
export * from './useSavedPodcastsPage';
export * from './useRecentNotesPage';
export * from './usePaymentsPage';
export * from './useReferFriendPage';
export * from './useTopicPage'
export * from './useSpeakerPage';
export * from './useCoursePage';
export * from './useAppLibraryToast';
export * from './usePodcastAppPage';
export * from './usePodcastAppLibrary';
export * from './usePodcastWebPage'
export * from './usePodcastPageBase';
export * from './useSearchPage';
export * from './useContactUs';
export * from './useRequestPayment';
export * from './useUserRole';
export const useIsAuthenticated = () => {
    return  useSelector(state => !!get(state,'user.user.token'));
}
