import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { RightSidebar, LeftSidebar } from "./components";
import styles from "./DashboardPage.module.scss";
import FeaturedPage from "./pages/FeaturedPage/FeaturedPage";
import IntroCoursesPage from "./pages/IntroCoursesPage/IntroCoursesPage";
import RecentPage from "./pages/RecentPage/RecentPage";
import SavedPodcastsPage from "./pages/SavedPodcastsPage/SavedPodcastsPage";
import SavedSpeakersPage from "./pages/SavedSpeakersPage/SavedSpeakersPage";
import SavedTopicsPage from "./pages/SavedTopicsPage/SavedTopicsPage";
import SavedCoursesPage from "./pages/SavedCoursesPage/SavedCoursesPage";
import SearchPage from "./pages/SearchPage/SearchPage";
import SeriesPage from "./pages/SeriesPage/SeriesPage";
import SpeakersPage from "./pages/SpeakersPage/SpeakersPage";
import TopicsPage from "./pages/TopicsPage/TopicsPage";
import PodcastDetails from "./pages/PodcastPage/PodcastDetails/PodcastDetails";
import RequestPayoutPage from "./pages/PodcastPage/RequestPayoutPage/RequestPayoutPage";
import ApplicationCTABanners from "../../components/ApplicationCTABanners/ApplicationCTABanners";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getSavedTopicsIds,
  getSavedSpeakersIds,
  getSavedCoursesIds,
  getSavedPodcastIds,
} from "@podcasts/store/dashboard";
import { useIsAuthenticated } from "@podcasts/hooks";
import referImage from "../../assets/images/refer-a-friend.png";


export function DashboardPage() {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const params = useParams();
  const navigate = useNavigate();
  const authParams = ["saved-podcasts", "saved-speakers", "saved-topics", "saved-courses"];

  
  useEffect(() => {
    // TO DO
    // combine all in one action and use in web and app
    const param = params["*"] || "";
    if ((authParams.includes(param) || param.endsWith("/payment")) && !isAuthenticated) {
      navigate("/login");
    }

    if(isAuthenticated) {
      dispatch(getSavedTopicsIds());
      dispatch(getSavedSpeakersIds());
      dispatch(getSavedCoursesIds());
      dispatch(getSavedPodcastIds())
    }
  }, [isAuthenticated]);

  return (
    <div className={`${styles["dashboard-page"]}`}>
      <LeftSidebar />
      <div className={`${styles["dashboard-page-main"]}`}>
        <Routes>
          <Route path={`/featured`} element={<FeaturedPage />} />
          <Route path={`/intro-courses`} element={<IntroCoursesPage />} />
          <Route path={`/speakers/*`} element={<SpeakersPage />} />
          <Route path={`/topics/*`} element={<TopicsPage />} />
          <Route path={`/courses/*`} element={<SeriesPage />} />
          <Route path={`/search`} element={<SearchPage />} />
          <Route path={`/saved-podcasts`} element={<SavedPodcastsPage />} />
          <Route path={`/saved-speakers`} element={<SavedSpeakersPage />} />
          <Route path={`/saved-topics`} element={<SavedTopicsPage />} />
          <Route path={`/saved-courses`} element={<SavedCoursesPage />} />
          <Route path={`/recent`} element={<RecentPage />} />
          <Route path={`:id/payment`} element={<RequestPayoutPage />} />
          <Route path={`:id`} element={<PodcastDetails />} />
        </Routes>
        {!isAuthenticated && <ApplicationCTABanners />}
        {isAuthenticated &&
            <ApplicationCTABanners
              isAuthenticated={isAuthenticated}
              title="Refer a friend and receive a $15 referral bonus for every friend that joins the program!"
              buttonName={"Refer a friend"}
              buttonLink={"/profile/refer"}
              rightImage={referImage}
              rightImageAlt={"refer a friend"}
            />
        }
      </div>
      <RightSidebar />
    </div>
  );
}

export default DashboardPage;
