import { useCallback, useState } from "react";
import { useCoursesLibraryBase } from './library-base';
import { useCourses } from './api';
import { useCourseLibraryAppToast } from './useAppLibraryToast';
import { useCourseLibraryWebToast } from './useWebLibraryToast';

export const useCoursesBasePage = (props) => {
    const {
      per_page = 12, 
      order_by = 'title', 
      defaultOrder = 'asc',
      onAddFinish,
      onRemoveFinish,
      onUnathorized,
    } = props;
    const [page,setPage] = useState(1);
    const [order, setOrder] = useState(defaultOrder)
    const { isSavedCourse, toggleCourseLibrary, isAuthenticated } = useCoursesLibraryBase({ onAddFinish, onRemoveFinish, onUnathorized});
    const { 
      data,
      fetchNextPage,
      isFetchingPreviousPage,
      isFetchingNextPage,
      isFetching,
      isRefetching } = useCourses({ page, per_page, order_by, order });
  
    const onNextPage = useCallback(() => {
      if (!isFetchingPreviousPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, [isFetchingPreviousPage, isFetchingNextPage]);
  
    const onSaveCourse = useCallback((item) => {
      toggleCourseLibrary(item)
   },[isAuthenticated]) 

   const onPageChange = useCallback((p) => {
    setPage(p)
   },[])

   const toggleOrder = useCallback(() => {
    setOrder(order === "asc" ? "desc" : "asc")
    setPage(1)
  },[order])
  
    return {
        data,
        order,
        setOrder,
        toggleOrder,
        isSavedCourse,
        fetchNextPage,
        isFetchingPreviousPage,
        isFetchingNextPage,
        isFetching,
        isRefetching,
        onNextPage,
        onPageChange,
        onSaveCourse,
        isAuthenticated
    }  
}

export const useCoursesPage = ({ per_page = 12, order_by = 'title', defaultOrder = 'asc' }) => {
  const toast = useCourseLibraryWebToast();
  return useCoursesBasePage({ per_page, order_by, defaultOrder, ...toast })
}

export const useCoursesAppPage = ({ per_page = 12, order_by = 'title', defaultOrder = 'asc' }) => {
   const toast = useCourseLibraryAppToast();
   return useCoursesBasePage({ per_page, order_by, defaultOrder, ...toast })
}