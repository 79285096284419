import React, { useState, Fragment, useEffect } from "react";
import styles from "./Header.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { size, get } from "lodash";
import { DropDown } from "../DropDown/DropDown";
import { userSelector } from "@podcasts/store/user"
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "@podcasts/store/user"
import { useIsAuthenticated, useUserRole } from "@podcasts/hooks";
import { changeLevel } from "@podcasts/store/search";
import LogRocket from "logrocket";

export function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(userSelector) as any;
  const { isStudent } = useUserRole() as any;
  const isAuthenticated = useIsAuthenticated()
  const [openMenu, setOpenMenu] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    if (user && user.id) {
      LogRocket.identify(user.id, {
        email: user.email,
        name: `${user.fname} ${user.lname}`
      });
    }
  }, [user])
  
  const links = [
    {
      name: "Podcast Library",
      path: "/podcasts/featured",
      icon: "icon-lock",
      sub: [
        {
          name: "About",
          path: "/about",
        },
        {
          name: "About",
          path: "/about",
        },
        {
          name: "About",
          path: "/about",
        },
        {
          name: "About",
          path: "/about",
        },
        {
          name: "About",
          path: "/about",
        },
        {
          name: "About",
          path: "/about",
        },
      ],
    },
    {
      name: "About",
      path: "/about",
      icon: "icon-lock",
    },
    {
      name: "Contact",
      path: "/contact",
      icon: "icon-lock",
    },
    {
      name: "Donate",
      path: "/donate",
      icon: "icon-lock",
    },
    {
      name: "Trivia",
      path: "/trivia",
      icon: "icon-lock",
    },
    {
      name: "Mentor Apply",
      path: "/mentor-apply",
      icon: "icon-lock",
    },
    // {
    //     name: 'Your Messages',
    //     path: '/messages',
    //     icon: 'icon-lock',
    //     amount: 9999999,
    // }
  ];

  const list = [
    {
      label: "My Profile",
      value: "My Profile",
      onClick: () => {
        navigate("/profile");
      },
    },
    {
      label: "Log out",
      value: "Log out",
      onClick: async () => {
        await dispatch(logOut());
        dispatch(changeLevel("beginner"));
        navigate("/login");
      },
    },
  ];

  if (!isStudent && list.length < 3) {
    list.unshift( {
      label: "My Students",
      value: "My Students",
      onClick: () => {
        window.open(`${process.env.REACT_APP_ADMIN_URL}/students`, "_blank");
      },
    });
  }

  return (
    <header className={styles["header"]}>
      <div
        className={`${styles["overlay"]} ${openMenu ? styles["visible"] : ""}`}
        onClick={() => {
          setOpenMenu(false);
        }}
      ></div>

      <Link to={"/"} className={styles["logo"]}>
        <img src={require("../../assets/images/logo.png")
        } />
      </Link>

      <div
        className={`${styles["nav-wrapp"]} ${openMenu ? styles["opened"] : ""}`}
      >
        <nav>
          <ul>
            {links.map((l, i) => (
              <li
                key={i}
                className={`${l.path === pathname ? styles["active"] : ""}`}
                onClick={() => {
                  setOpenMenu(false);
                }}
              >
                <Link to={l.path} className={styles["nav-bar-link"]}>
                  {l.name}
                </Link>
              </li>
            ))}{" "}
          </ul>
        </nav>
        <ul className={styles["nav-auth-list"]}>
          {links.map((l: any, i) => (
            <li
              key={i}
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              <div className={styles["link-box"]}>
                <div className={styles["left-icon-box"]}>
                  <i className={l.icon}></i>
                </div>
                <Link to={l.path} className={styles["nav-bar-link"]}>
                  {l.name}
                </Link>
              </div>
              <div className={styles["right-icon-box"]}>
                {get(l, "amount", null) && (
                  <span className={styles["amount-box"]}>
                    <span>{l.amount}</span>
                  </span>
                )}
                {size(l.sub) > 0 && <i className="icon-chevron-right"></i>}
              </div>
            </li>
          ))}
        </ul>
        <ul className={styles["userPart"]}>
          <li className={styles["close"]}>
            <div
              className={styles["mobile-close-box"]}
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              <span></span>
            </div>
          </li>

          {isAuthenticated ? (
            <li>
              <DropDown
                list={list}
                onOptionClick={(value: any) => {}}
                child={
                  <div className={`${styles["userAvatar"]} ${styles["iconSize"]}`}>
                    {/* <i className="icon-user"></i> */}
                    <img src={user.image}/>
                  </div>
                }
              />
            </li>
          ) : (
            <Fragment>
              <li>
                <Link to={"/register"}>Apply</Link>
              </li>
              <li className={styles["separator"]}>/</li>
              <li>
                <Link to={"/login"}>Login</Link>
              </li>
            </Fragment>
          )}
        </ul>
        {/* <div className={styles['mobile-close-box']} onClick={() => { setOpenMenu(false) }}>
            <span></span>
        </div> */}
      </div>
      <ul className={styles["mobile-box"]}>
        <li>
          <Link to={"/register"}>Apply</Link>
        </li>
        <li>
          <div
            className={`${styles["burger"]} ${styles["iconSize"]}`}
            onClick={() => {
              setOpenMenu(true);
            }}
          >
            <span></span>
          </div>
        </li>
      </ul>
    </header>
  );
}

export default Header;
