import React, { useState, useEffect, useRef } from "react";
import styles from "./MultiSelect.module.scss";
import FilterIcon from "../FilterIcon/FilterIcon";

export const MultiSelect = ({
  selected = [],
  options = [],
  onChange,
  label = "",
  type = "",
}: any) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ref: any = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowDropDown(false);
    }
  };

  const onClick = () => {
    setShowDropDown((prev) => !prev);
  };

  const isSelected = (id: number) => selected.includes(id);

  return (
    <div className={`${styles["drop-down"]}`} ref={ref}>
      <section onClick={onClick}>
        {label}
        <i className="icon-select"></i>
      </section>
      {showDropDown ? (
        <div
          className={`${styles["drop-down-wrapp"]}`}
          style={{ display: "block" }}
        >
          <ul>
            {options.map((item: any, index: number) => (
              <li
                key={index}
                onClick={() => {
                  onChange(item);
                }}
              >
                <div
                  className={`${
                    isSelected(item.id) && styles["active"]
                  } d-flex align-items-start`}
                >
                  <span className={`${styles["checkbox"]}`}>
                    <label htmlFor={`filter` + index}>
                      <FilterIcon active={isSelected(item.id)} />
                    </label>
                  </span>
                  <span className={`${styles["filter-text"]}`}>
                    {label==="Speakers" && item.title} {item.name}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
