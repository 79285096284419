import React, { useState, useEffect, createRef, useRef, Children } from "react";
import { Link } from "react-router-dom";
import styles from "./DropDown.module.scss";

export const DropDown = ({ child, list, onOptionClick }: any) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const ref: any = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowDropDown(false);
    }
  };

  return (
    <div className={`${styles["drop-down"]}`} ref={ref}>
      <section
        onClick={() => {
          setShowDropDown((prev) => !prev);
        }}
      >
        {child}
        {showDropDown}
        <ul style={showDropDown ? { display: "block" } : { display: "none" }}>
          {list.map((i: any, item: number) => (
            <li key={item} style={i.styles || {}}>
              <span
                style={i.style ? i.style : {}}
                //to={ i.link ? i.link : '#' }
                onClick={() => i.onClick()}
              >
                {i.label}
              </span>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};
