import { useEffect, useState } from "react";
import { MultiSelect } from "../MultiSelect/MultiSelect";
import styles from "./SearchInput.module.scss";
import { map, isEqual, filter, chain } from "lodash";

export const SearchInput = ({
  speakers = [],
  topics = [],
  courses = [],
  onSearchChange,
  view = "list",
  onViewChange,
}: any) => {
  const [search, setSearch] = useState("") as any;
  const [selected, setSelected] = useState([]) as any;

  const onChange = (option: any) => {
    setSelected((options: any) => {
      const index = selected.findIndex((o: any) => isEqual(o, option));
      if (index === -1) {
        return [...selected, option];
      }
      return options.filter((i: any) => !isEqual(i, option));
    });
  };

  useEffect(() => {
    const query: any = {};
    if (search.length > 0) {
      query.search = search;
    }
    const result = chain(selected).groupBy("type").value();

    map(result, (v, k) => {
      query[k] = map(v, "id");
    });

    onSearchChange(query);
  }, [selected, search]);

  const toType = (type: string, option: any) => ({ ...option, type });

  const selectedIdsByType = (type: string) =>
    map(filter(selected, { type }), "id");

  return (
    <div>
      <section className={`${styles["search-container"]}`}>
        <div className={`${styles["search-input"]} d-flex align-items-center`}>
          <i className="icon-search"></i>
          <input
            placeholder="Start typing to search"
            onKeyDown={({ key, target: { value } }: any) => {
              if (key === "Enter") {
                setSearch(value);
              }
            }}
          />
        </div>
        <ul>
          <li>
            <MultiSelect
              label={"Speakers"}
              onChange={onChange}
              selected={selectedIdsByType("speaker")}
              options={speakers.map((i: any) => toType("speaker", i))}
            />
          </li>
          <li>
            <MultiSelect
              label={"Series / Courses"}
              onChange={onChange}
              selected={selectedIdsByType("course")}
              options={courses.map((i: any) => toType("course", i))}
            />
          </li>
          <li>
            <MultiSelect
              label={"Topics"}
              onChange={onChange}
              selected={selectedIdsByType("topic")}
              options={topics.map((i: any) => toType("topic", i))}
            />
          </li>
        </ul>
        <div
          className={`${styles["view-box"]} d-flex align-items-center`}
          onClick={onViewChange}
        >
          <span>{view === "list" ? "Grid" : "List"} View</span>
          <i className={`icon-${view === "grid" ? "list" : "grid "}`}></i>
        </div>
      </section>
      <ul className={`${styles["tag_list"]} d-flex align-items-center`}>
        {selected.map((o: any, i: number) => (
          <li key={i}>
            <span>{o.name}</span>
            <span
              onClick={() => {
                onChange(o);
              }}
              className={`${styles["close"]}`}
            >
              &times;
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchInput;
