import { useCallback, useState } from 'react'
import { useTopicLibraryBase } from './library-base';
import { useTopics } from './api';
import { useTopicLibraryAppToast } from './useAppLibraryToast';
import { useTopicLibraryWebToast } from './useWebLibraryToast';

export const useTopicsBasePage = (props) => {

    const {
      per_page = 12, 
      order_by = 'title', 
      defaultOrder = 'asc',
      onAddFinish,
      onRemoveFinish,
      onUnathorized,
    } = props;
    
    const [page,setPage] = useState(1);
    const [order, setOrder] = useState(defaultOrder)
    const { isSavedTopic, toggleTopicLibrary, isAuthenticated} = useTopicLibraryBase({ onAddFinish, onRemoveFinish, onUnathorized});
    const { 
      data,
      fetchNextPage,
      isFetchingPreviousPage,
      isFetchingNextPage,
      isFetching,
      isRefetching } = useTopics({ page, per_page, order_by, order });
  
    const onNextPage = useCallback(() => {
      if (!isFetchingPreviousPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, [isFetchingPreviousPage, isFetchingNextPage]);
  
    const onSaveTopic = useCallback((item) => {
      toggleTopicLibrary(item)
   },[isAuthenticated]) 

   const onPageChange = useCallback((p) => {
    setPage(p)
   },[])

   const toggleOrder = useCallback(() => {
    setOrder(order === "asc" ? "desc" : "asc")
    setPage(1)
  },[order])
   
 
   return { 
    data,
    order,
    setOrder,
    isSavedTopic,
    toggleOrder,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    isFetching,
    isRefetching,
    onNextPage,
    onPageChange,
    onSaveTopic,
    isAuthenticated
   }
}

export const useTopicsPage = ({ per_page = 12, order_by = 'title', defaultOrder = 'asc' }) => {
  const toast = useTopicLibraryWebToast()
  return useTopicsBasePage({ per_page, order_by, defaultOrder, ...toast })
}

export const useTopicsAppPage = ({ per_page = 12, order_by = 'title', defaultOrder = 'asc' }) => {
   const toast = useTopicLibraryAppToast()
   return useTopicsBasePage({ per_page, order_by, defaultOrder, ...toast })
}