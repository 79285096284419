import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  applyFirstStep,
  applySecondStep,
  applytThirdStep,
  getIncompleteUserData,
  sendConfirmationEmailToStudent,
} from "@podcasts/api";
import { get, lowerCase, includes } from "lodash";

const initialState = {
  step: 1,
  user: {
    email: "",
    fname: "",
    lname: "",
    phone: "",
    gender: "",
    dob: "",
    city_residence: "",
    country: null,
    state_residence: "",
    // second step
    religion_born: "",
    religion_identify_with: "",
    father_religion: "",
    mother_religion: "",
    jewish_story: "",
    jewish_education_description: "",
    jewish_education: false,
    // third step
    position: "Student",
    referral_method: null,
    other_referral_method: null,
    school: "",
    city: "",
    state: "",
    internship: "",
    referral_name: "",
    token: "",

  },
  error: "",
  errorTitle: "",
  buttons: [
    { label: "", link: "" }
  ],
  token: "",
  resetPasswordToken: null,
  getUserSuccessMessage: null,
  sendConfirmationEmail: null,
};

export const setFirstStep = createAsyncThunk(
  "app/firstStep",
  async (options, { rejectWithValue }) => {
    try {
      const { data } = await applyFirstStep(options);
      data.dob = new Date(data.dob);
      return data;
    } catch (error) {
      // toast.show(error.response.data.message, {
      //   placement: "bottom",
      //   type: "danger",
      //   offset: 0,
      // });
      return rejectWithValue(error.response.data);
    }
  }
);

export const setSecondStep = createAsyncThunk(
  "app/secondStep",
  async (options, { rejectWithValue, getState }) => {
    const token = get(getState(), "application.user.token");
    try {
      const { data } = await applySecondStep(options, token);
      data.jewish_education = Boolean(data.jewish_education);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetState = createAsyncThunk(
  "app/resetState",
  async (options, { rejectWithValue, getState }) => {
    try {
      return {};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const setThirdStep = createAsyncThunk(
  "app/thirdStep",
  async (options, { rejectWithValue, getState }) => {
    try {
      const token = get(getState(), "application.user.token");
      const { data } = await applytThirdStep(options, token);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIncompleteUser = createAsyncThunk(
    "app/getIncompleteUser",
    async (options, { rejectWithValue, getState }) => {
      try {
        const { data } = await getIncompleteUserData(options);
        return data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const sendConfirmationEmail = createAsyncThunk(
    "app/sendConfirmationEmail",
    async (options, { rejectWithValue, getState }) => {
      try {
        const token = get(getState(), "application.user.token");
        const { data } = await sendConfirmationEmailToStudent(token);
        return data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    nextStep(state) {
      if (state.step <= 5) {
        state.step = state.step + 1;
      }
    },
    prevStep(state) {
      if (state.step >= 1) {
        state.step = state.step - 1;
      }
    },
    setStep(state, options) {
      if (+options.payload <= 5 && +options.payload >= 1) {
        state.step = options.payload;
      }
    },
  },
  extraReducers: {
    // first step
    [setFirstStep.pending]: (state, action) => {
      state.resetPasswordToken = null;
      state.error = "";
    },
    [setFirstStep.fulfilled]: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.step = 2;
      state.resetPasswordToken = null;
    },
    [setFirstStep.rejected]: (state, action) => {
      if (action.payload.error === "need_password_reset") {
        state.error = "reset";
        state.resetPasswordToken = action.payload.token;
      } else {
        state.error = action.payload.message;
      }

      if (action.payload.error === "pending") {
        state.step = 6;
        state.errorTitle = "We have received your application";
        state.error = "We will notify you of any change to your application status. " +
            "Please contact us with any questions.";
        state.buttons = [
          {
            "label": "Checkout podcast library",
            "link": "/podcasts/featured",
          },
          {
            "label": "Contact us",
            "link": "/contact",
          },
        ];
      } else if (action.payload.error === "accepted") {
        state.step = 6;
        state.errorTitle = "We’ve been waiting for you!";
        state.error = "Your account is ready to go, we just need you to reset your password. " +
            "Please contact us if you have any questions.";
        state.user.email = action.payload.email;
        state.buttons = [
          {
            "label": "resend-password",
            "link": "/",
          },
          {
            "label": "Contact us",
            "link": "/contact",
          },
        ];
      } else if (action.payload.error === "rejected") {
        state.step = 6;
        state.errorTitle = "Thank you for your interest in Podcast Fellowships.";
        state.error = "We are not currently accepting new students at this time. " +
            " Please contact us at a later date.";
        state.buttons = [
          {
            "label": "Checkout podcast library",
            "link": "/podcasts/featured",
          }
        ];
      }
    },
    // second step
    [setSecondStep.pending]: (state, action) => {},
    [setSecondStep.fulfilled]: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.step = 3;
    },
    [setSecondStep.rejected]: (state, action) => {
      state.error = "Post second step error";
    },
    // third step
    [setThirdStep.pending]: (state, action) => {},
    [setThirdStep.fulfilled]: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      const isBornJewish = includes(lowerCase(get(state,"user.mother_religion","")),"j");
      state.step = isBornJewish ? 4 : 5;
    },
    [setThirdStep.rejected]: (state, action) => {
      state.error = "Post Third step error";
    },
    [resetState.fulfilled]: (state, action) => {
      state.error = "";
      state.errorTitle = "";
      state.step = 1;
      state.resetPasswordToken = null;
      state.user = { ...state.user, email: null, referral_method: null };
    },
    [getIncompleteUser.pending]: (state, action) => {
      state.getUserSuccessMessage = null;
    },
    [getIncompleteUser.fulfilled]: (state, action) => {
      state.user = { ...state.user, ...action.payload.data.student, ...action.payload.data.student.user, token: action.payload.token };
      state.step = action.payload.data.step;
      state.getUserSuccessMessage = "Complete your application to earn $15 for each podcast you listen to and discuss with your mentor!";
    },
    [getIncompleteUser.rejected]: (state, action) => {
      state.getUserSuccessMessage = null;
    },
    [sendConfirmationEmail.pending]: (state, action) => {
      state.sendConfirmationEmail = null;
    },
    [sendConfirmationEmail.fulfilled]: (state, action) => {
      state.sendConfirmationEmail = true;
    },
    [sendConfirmationEmail.rejected]: (state, action) => {
      state.sendConfirmationEmail = null;
    },
  },
});

export const { setStep, nextStep, prevStep } = appSlice.actions;
export const appReducer = appSlice.reducer;
