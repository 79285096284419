import styles from "./SpeakersPage.module.scss";
import { useRef } from "react";
import SpeakerCard from "../../components/SpeakerCard/SpeakerCard";
import { Pagination } from "../../../../../../components";
import { useSpeakersPage } from "@podcasts/hooks";

export function SpeakersItem() {
  const myRef: any = useRef(null);

  const { 
    data: { data, last_page, current_page },
    order,
    setOrder,
    isSavedSpeaker,
    onPageChange,
    onSaveSpeaker
  } = useSpeakersPage({ per_page: 12, defaultOrder: "asc", order_by: "lname" });

  const executeScroll = () =>
  myRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <div
       ref={myRef}
        className={`${styles["title-box"]} d-flex justify-content-between align-items-center`}
      >
        <h2>Speakers</h2>
        <div className={`${styles["sort"]} d-flex align-items-center`} onClick={() => setOrder(order === "asc" ? "desc" : "asc")} >
          <span>Sort&nbsp;</span>
          <div className={`${order === "desc" ? styles["up"] : styles["down"]}`}>
            <i className="icon-chevron-sown"></i>
          </div>
        </div>
      </div>

      <div className={`${styles["topics-items"]}`}>
      {data.map((item: any, i: number) => (
        // <SpeakerItem
        //   key={i}
        //   saved={isSaved(item.id)}
        //   onToggleFavorite={() => {
        //     dispatch(saveSpeaker(item));
        //   }}
        //   {...item}
        // ></SpeakerItem>

        <div key={i} className={`${styles["topic-wrapp"]}`}>
            <SpeakerCard
              {...item}
              saved={isSavedSpeaker(item.id)}
              onToggleFavorite={(e) => {
                e.stopPropagation();
                onSaveSpeaker(item);
              }}
            {...item}
          />
          </div>
      ))}
      </div>

      <Pagination
        pages={last_page}
        page={current_page}
        onPageChange={(page: number) => {
          onPageChange(page)
          executeScroll();
        }}
      />

    </>
  );
}

export default SpeakersItem;
