import { useInfiniteQuery, useQuery, useMutation } from 'react-query';
import { SearchService, 
         getAllSpeakerPodcasts,
         getAllSpeakers, 
         getAllTopics, 
         getAllCoursesApi, 
         getTopicPodcastsApi,
         getCourseApi,
         getRecentlyViewedApi,
         getFeatured,
         getAllNotesApi,
         getSavedPodcastsApi,
         getPaymentsApi,
         LibraryService,
         getNotesByPodcastId,
         referFriendsApi,
         sendNotesToMentorApi,
         deleteNodeApi,
         getPodcastApi,
         updateNoteApi,
         getRequestPayOutApi,
         requestPayOutApi,
         getPodcastSpeakerApi,
         getPodcastTopicApi,
         saveRecentlyViewedApi,
         sendContactUsForm,
         sendForgetPasswordEmail,
         changeUserPassword
        } from "@podcasts/api";
import { last, map } from 'lodash';

const paginatedQuery = (queryKey,pageParam) => ({ ...queryKey[1], ...( pageParam ? { page: pageParam } : { }) })


const commonListOtions = {
  getNextPageParam: ({ current_page,last_page  }) => {
    console.log(current_page,last_page)
    return current_page < last_page ? current_page + 1 : undefined;
  },
  select:(_data) => {
    const pages = (_data.pages || []);
    const data = map(pages,'data').flat();
      const { last_page = 0, current_page = 1, total_amount = null } = last(pages);
      const result = { data, last_page, current_page };
      if (total_amount) result.total_amount = total_amount;
      return result;
  },
  initialData: {
      pages: [ { data: [], last_page: 1, current_page: 0 }], 
  },
}

export const usePodcast = (id) => {
  return useQuery(
    ["podcast", id],
    () => getPodcastApi(id).then((d) => d.data))
}

export const useResetPasswordMutation = () => useMutation({
  mutationFn: (body) =>
  changeUserPassword(body)
})

export const useRequestPayoutMutation = () => 
useMutation({
  mutationFn: (body) =>
  requestPayOutApi(body)
})

export const useGetPayOutRequest = (id) => {
  return useQuery(
    ["payout-request", id],
    () => getRequestPayOutApi(id).then((d) => d.data))
}


export const useSearch = (filters = {}) => {
    return useInfiniteQuery(
      ["search", filters],
      ({ queryKey, pageParam }) =>
       SearchService.getPodcasts(paginatedQuery(queryKey,pageParam)).then(d => d.data),
       commonListOtions
    );
};

export const useSpeakers = (filters = {}) => {
  return useInfiniteQuery(
    ["speakers2", filters],
    ({ queryKey, pageParam }) => getAllSpeakers(paginatedQuery(queryKey,pageParam)).then(d => d.data),
     commonListOtions
  );
};


export const useRecentNotes = (filters = {}) => {
  return useInfiniteQuery(
    ["recent-notes", filters],
    ({ queryKey, pageParam }) => getAllNotesApi(paginatedQuery(queryKey,pageParam)).then(d => d.data),
     commonListOtions
  );
};

export const useTopics = (filters = {}) => {
  return useInfiniteQuery(
    ["topics", filters],
    ({ queryKey, pageParam }) =>
      getAllTopics(paginatedQuery(queryKey,pageParam)).then(d => d.data),
      commonListOtions
  );
};

export const useCourses = (filters = {}) => {
  return useInfiniteQuery(
    ["courses", filters],
    ({ queryKey, pageParam }) =>
       getAllCoursesApi(paginatedQuery(queryKey,pageParam)).then(d => d.data),
       commonListOtions
  );
};

export const usePodcastNotes = (filters = {}) => {
  return useInfiniteQuery(
    [`podcast-notes`,filters.slug],
    ({ queryKey, pageParam }) => {
      console.log(queryKey,'query key')
       return getNotesByPodcastId(paginatedQuery({1:filters},pageParam)).then(d => d.data)},
       commonListOtions
  );
};

export const usePodcastSpeaker = (slag) => {
  return useQuery(
    ["podcast-speaker", slag],
    () => getPodcastSpeakerApi(slag).then((d) => d.data))
};


export const usePodcastTopic = (slag) => {
  return useQuery(
    ["podcast-topic", slag],
    () => getPodcastTopicApi(slag).then((d) => d.data))
};


export const useCourse = ({ slug }) => {
  return useQuery(
    ["course", slug],
    () => getCourseApi({ slug }).then((d) => d.data))
};

export const useSpeaker = ({slug }) => {
  return useQuery(
    ["speaker", slug],
    () => getAllSpeakerPodcasts({ slug }).then((d) => d.data))
};

export const useContact = () =>
    useMutation({
        mutationFn: (body) =>
            sendContactUsForm(body),
    });

export const useForgotPassword = () =>
    useMutation({
        mutationFn: (body) =>
        sendForgetPasswordEmail(body),
});
    


export const useTopic = ({ slug }) => {
  return useQuery(
    ["topic", slug],
    () => getTopicPodcastsApi({ slug }).then((d) => d.data))
};

export const useFeatured = (options) => {
  return useQuery({
    queryKey: ['featured', options],
    queryFn: () => getFeatured(options).then((d) => d.data),
    initialData: () => ( {
      topic: null,
      course: null, 
      speaker: null,
      topicsCount: 0,
      coursesCount: 0,
      speakersCount: 0,
      podcasts: []
  })
  })
};

export const usePayments = (filters = {}) => {

    return useInfiniteQuery(
        ["payments", filters],
        ({ queryKey, pageParam }) =>
            getPaymentsApi(paginatedQuery(queryKey, pageParam)).then(d => d.data),
        commonListOtions
    );
};

export const refereAfriend = () => 
  useMutation({
    mutationFn: (friends) =>
      referFriendsApi(friends),
})

export const saveToRecentlyViewed = () => 
  useMutation({
    mutationFn: (podcastSlug) =>
    saveRecentlyViewedApi(podcastSlug),
})

export const useDeleteNote = () => 
  useMutation({
    mutationFn: (id) =>
      deleteNodeApi(id),
})


export const useUpdateNote = () => 
  useMutation({
    mutationFn: ({ id, note }) =>
    updateNoteApi(id,note),
})

export const useSendNotesToMentor = () => 
  useMutation({
    mutationFn: (slug) =>
    sendNotesToMentorApi(slug),
})



export const useRecentlyViewed = (filters = {}) => {
  return useInfiniteQuery(
    ["recently-viewed", filters],
    ({ queryKey, pageParam }) =>
    getRecentlyViewedApi(paginatedQuery(queryKey,pageParam)).then(d => d.data),
       commonListOtions
  );
};

export const useSavedSpeakers = (filters = {}) => {
  return useInfiniteQuery(
    ["saved-speakers", filters],
    ({ queryKey, pageParam }) =>
    LibraryService.getSavedSpeakersApi(paginatedQuery(queryKey,pageParam)).then(d => d.data),
       commonListOtions
  );
};

export const useSavedTopics = (filters = {}) => {
  return useInfiniteQuery(
    ["saved-topics", filters],
    ({ queryKey, pageParam }) =>
    LibraryService.getSavedTopics(paginatedQuery(queryKey,pageParam)).then(d => d.data),
       commonListOtions
  );
};


export const useSavedCourses = (filters = {}) => {
  return useInfiniteQuery(
    ["saved-courses", filters],
    ({ queryKey, pageParam }) =>
    LibraryService.getSavedCourses(paginatedQuery(queryKey,pageParam)).then(d => d.data),
       commonListOtions
  );
};

export const useSavedPodcasts = (filters = {}) => {
  return useInfiniteQuery(
    ["saved-podcasts", filters],
    ({ queryKey, pageParam }) =>
    getSavedPodcastsApi(paginatedQuery(queryKey,pageParam)).then(d => d.data),
       commonListOtions
  );
};

