import { useRef } from "react";
// import { DropDown } from "../../../../../../components/DropDown/DropDown";
import TopicsItem from "../../../TopicsPage/components/TopicsItem/TopicsItem";
import styles from "./SeriesListPage.module.scss";
import { Pagination } from "../../../../../../components";
import { useCoursesPage } from "@podcasts/hooks";

export function SeriesListPage() {
  const myRef: any = useRef(null);
    
  const { 
    data: { data, last_page, current_page },
    order,
    setOrder,
    isSavedCourse,
    onPageChange,
    onSaveCourse
  } = useCoursesPage({ per_page: 12, defaultOrder: "asc", order_by: "title" })

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <div
        ref={myRef}
        className={`${styles["title-box"]} d-flex justify-content-between align-items-center`}>
        <h2>Our Courses</h2>
        <div className={`${styles["sort"]} d-flex align-items-center`} onClick={() => setOrder(order === "asc" ? "desc" : "asc")} >
          <span>Sort&nbsp;</span>
          <div className={`${order === "desc" ? styles["up"] : styles["down"]}`}>
            <i className="icon-chevron-sown"></i>
          </div>
        </div>
      </div>
      <div className={`${styles["topics-items"]}`}>
        {data.map((item: any, i: number) => (
          <div key={i} className={`${styles["topic-wrapp"]}`}>
            <TopicsItem
              {...item}
              type={"courses"}
              saved={isSavedCourse(item.id)}
              onToggleFavorite={(e) => {
                e.stopPropagation();
                onSaveCourse(item);
              }}
            />
          </div>
        ))}
      </div>
      <Pagination
        pages={last_page}
        page={current_page}
        onPageChange={(page: number) => {
          onPageChange(page)
          executeScroll();
        }}
      />
    </>
  );
}

export default SeriesListPage;
